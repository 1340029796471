export const label = {
  ANKEN_EDABAN: '案件枝番',
  ANKEN_NAIYO: '案件内容',
  ANKEN_NO: '案件No',
  ANKEN_SOUGAKU: '案件総額',
  ANKEN_SOUJUURYO: '案件総重量',
  ARMS_NYUKINSHORI: 'ARMS\r\n入金処理',
  ARMS_SHUKKASHORI: 'ARMS\r\n出荷処理確認',
  BOX_NO: 'BoxNo',
  BOX_TYPE: '箱タイプ',
  BUYER_NAME: 'バイヤー名',
  CARTON_NO: 'CartonNo',
  CENTER_TENPU_SHORUI_KAKUNIN: 'センター\r\n添付書類確認',
  CENTER_TENPU_SHORUI: 'センター\r\n添付書類',
  COMMENT: 'コメント',
  COUNTRY: '国名',
  COURIER: 'クーリエ',
  DAISUU: '台数',
  DOC_DL: '書類DL',
  EDIT: '編集',
  GOUKEI: '合計',
  GROSS_WEIGHT: '総重量',
  HAKO: '箱',
  HAKOMATOMEZUMI: '箱まとめ済',
  HENKO_DATETIME: '出荷書類\r\n変更日時',
  HIKIWATASHI_CANCEL: '引き渡し\r\nキャンセル',
  HIKIWATASHI_DATETIME: '引き渡し日時',
  HIKIWATASHI_KANRYO: '引き渡し\r\n完了',
  HIKIWATASHI_MAE: '引渡前',
  INVOICE_BANGO: 'Invoice番号',
  INVOICE_DATE: 'Invoice発行日',
  INVOICE_KAKAKU: 'Invoice価格',
  INVOICE_SOUGAKU: 'Invoice総額',
  JUUTORITSU: '充当率',
  KAIIN_ID: '会員ID',
  KINGAKU: '金額',
  KOUTEI: '工程',
  KONPOMEISAI_DATETIME: '修正日時',
  SHUSEI_TANTOSHA: '修正担当者',
  KONPONAIYOSHUSEI: '梱包明細作成\r\n内容修正',
  MINYUKIN: '未入金',
  MINYUKINSHUKKA: '未入金出荷',
  MISHUKKA: '未出荷',
  NET_WEIGHT: '純重量',
  NYUKIN_DATE: '入金日',
  PICKING_KANRYO_KAKUNIN: 'ピッキング\r\n完了確認',
  REMARK: '備考',
  SEIHINMEI: '商品名',
  SHIPPING_REMARK_ARI: 'Shipping備考あり',
  SHIPPING_REMARK_CONFIRM: 'Shipping\r\n備考確認',
  SHIPPING_REMARK: 'Shipping備考',
  SHORUI: '書類',
  SHORUI_SAKUSEI_CHU: '書類作成中',
  SHORUISASHIKAE: '出荷書類\r\n差し替え',
  SHUKKA_ANKEN_NO: '出荷案件',
  SHUKKA_CANCEL: '出荷キャンセル',
  SHUKKA_DATA_TSUSHIN: '出荷データ送信',
  SHUKKA_PDF_ZIP: '出荷PDF',
  SHUKKA_DATE: '出荷日',
  SHUKKA_SHORUI: '出荷書類',
  SHUKKA_YOTEI_KAKUTEI_MAE: '出荷予定確定前',
  SHUKKA_YOTEI_KAKUTEI_GO: '出荷予定確定後',
  SHUKKA_YOTEIBI_KAUTEI: 'ー出荷予定日確定',
  SHUKKA_YOTEIBI_MIKAUTEI: 'ー出荷予定日未確定',
  SHUKKAHORYU: '出荷保留',
  SHUKKAHORYUANKEN: 'ー出荷保留案件',
  SHUKKATOSHI_NO: '出荷通しNo',
  SHUKKATOSHIBANGOFURIWAKE: '出荷通し番号\n振分',
  SHUKKAYOTEI_DATE: '出荷予定日',
  SOUFUSAKI: '送付先',
  STATUS: 'ステータス',
  SUURYOU: '数量',
  TAIOZUMI: '対応済',
  CHECKTANTO: '送信結果\r\nチェック',
  SHUTANTO: '主担当',
  TANTOSHA: '担当者',
  TENPU_SHORUI: '運営\r\n添付書類',
  TRACKING_NO: '追跡番号',
  WEIGHT: '重量',
  PACKAGING_MATERIALS_WEIGHT: '重量',
  HEIGHT: '縦幅',
  WIDTH: '横幅',
  DEPTH: '高さ',
  YOTEIBIMINYURYOKU: '予定日未入力',
  YOUSEKI: '容積',
  YOYAKU_BANGO: '予約番号',
  YUUSENJUNI: '優先順位',
  BTN_ADD: '入力',
  BTN_ANKEN_CANCEL: '案件キャンセル',
  BTN_AUTO_MATOME: '自動まとめ',
  BTN_BOX_CANCEL: 'Boxキャンセル',
  BTN_UNDO_BOX_CANCEL: 'Boxキャンセル取消',
  BTN_BUNKATSU_ANKEN_MODOSHI: '分割案件戻し',
  BTN_RESET: 'リセット',
  BTN_CANCEL: 'キャンセル',
  BTN_CONFIRM: '確認',
  BTN_CSV_INPUT: 'CSV取込',
  BTN_CSV_OUTPUT: 'CSV出力',
  BTN_AUTO_NUMBER: '自動採番',
  BTN_DOWNLOAD: 'DL',
  BTN_HAKO_MATOME_LIST_DL: '箱まとめリストDL',
  BTN_HENKO_HOZON: '変更保存',
  BTN_IKKI_DOWNLOAD: '一括DL',
  BTN_INVOICE_DETAILS_SHINKI: 'Invoice・Details取込',
  BTN_EXECUTE: '実行',
  BTN_KONPO_MEISAI_DL: '梱包明細DL',
  BTN_NYUKIN_CSV: '入金データCSV取込',
  BTN_PREVIEW: 'プレビュー',
  BTN_SEARCH: '検索',
  BTN_SELECT: '選択',
  BTN_CHANGE: '変更',
  BTN_CHECK: 'チェック',
  BTN_SENTAKU_CARTON_BETSU_ANKENKA: '選択Carton別案件化',
  BTN_SHUKKA_YOTEIBI_SETTEI: '出荷予定日設定',
  BTN_WEIGHT_LIST_TORIKOMI: '重量リスト取込',
  BTN_SHUKKA_SHIJI_KAIJO: '出荷指示解除',
  BTN_SHUKKA_SHORUI_DL: '出荷書類ダウンロード',
  BTN_YOYAKUBANGO_IKKI_NYURYOKU: '予約番号一括入力',
  KEY_STRING: '定数キー',
  NAME: '名前',
  VALUE_1: '値1',
  VALUE_2: '値2',
  VALUE_3: '値3',
  VALUE_4: '値4',
  VALUE_5: '値5',
  DISCRIPTION: '説明',
  ORDER: '表示順',
  REGISTER: '登録',
  DELETE: '削除',
  DELETE_CONFIRM: '削除確認',
  REGISTER_CONFIRM: '登録確認',
  COMPLETE: '完了',
  CLOSE: '閉じる',
  UPDATE: '更新',
  UPDATE_CONFIRM: '更新確認',
  MULTI_DOWNLOAD_BUTTON: '一括DL',
  PROGRESS: '進捗',
  CSL_MATCHING: 'CSL照合',
  MATCHING: '照合',
  MEMBER_NAME: '会員名',
  ACCOUNT_HOLDER: 'アカウントホルダー社名',
  SHIP_TO_COMPANY_NAME: '送付先社名',
  CEO_NAME: 'CEO Name',
  CSL_MATCHING_RESULT: 'CSL照合結果',
  SHIPPING_SEQ_NUMBER: '出荷通し番号',
  CSL_MATCHING_CONFIRM: 'CSL照合確認',
  CSL_MATCHING_MEMO: 'CSL照合備考',
  CSL_MATCHING_LOG: 'CSL照合ログ出力',
  CSL_MATCHING_DATE_FROM: 'CSL照合日 From',
  CSL_MATCHING_DATE_TO: 'CSL照合日 To',
  HOUR: '時',
  MINUTE: '分',
  NUM_OF_MATCHING: '照合件数',
};

export const maruNum = ['①', '②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨', '⑩', '⑪', '⑫', '⑬'];

export const executePage = {
  CREATE_SHIPPING_DOC: 1,
  SHIPPED_MATTER_LIST: 2,
};

/** Vuetify準拠の宣言 */
// https://vuetifyjs.com/ja/components/selects/#section-30ab30b930bf30e0text3068value
export const progressStatus = {
  seiyakuAnkenKakunin: [
    { text: '総件数', value: 'TOTAL' },
    { text: '作業中', value: 'DOING' },
    { text: '未入金', value: 'UNPAID' },
  ],
  shukkaAnkenKakutei: [
    { text: '総件数', value: 'TOTAL' },
    { text: '作業中', value: 'DOING' },
    { text: '作業済', value: 'DONE' },
    { text: '出荷保留', value: 'ON_HOLD' },
  ],
  shukkaShoruiSakusei: [
    { text: '総件数', value: 'TOTAL' },
    { text: '作業中', value: 'DOING' },
    { text: '作業済', value: 'DONE' },
  ],
  shukkaJunbiHikiWatashi: [{ text: '作業中', value: 'DOING' }],
};

export const courier = [
  { text: 'FEDEX', value: 1 },
  { text: 'UPS', value: 2 },
  { text: 'DHL', value: 3 },
  // { text: "３クーリエ以外", value: 4 },
];

export const inputs = {
  seiyakuAnkenKakunin: (statusItems) => ({
    invoiceDate: {
      label: label.INVOICE_DATE,
      isCalendar: true,
      range: true,
      colNum: 1,
      max: new Date().toISOString().slice(0, 10),
    },
    ankenNo: {
      label: label.ANKEN_NO,
      icon: 'mdi-file-document',
      isText: true,
      colNum: 1,
    },
    buyerName: {
      label: label.BUYER_NAME,
      icon: 'mdi-city-variant',
      isText: true,
      colNum: 2,
    },
    shippingRemarkAri: {
      label: label.SHIPPING_REMARK_ARI,
      isCheckbox: true,
      colNum: 2,
    },
    taiozumi: {
      label: label.TAIOZUMI,
      isCheckbox: true,
      colNum: 2,
    },
    status: {
      label: label.STATUS,
      icon: 'mdi-progress-question',
      isSelectBox: true,
      items: statusItems,
      colNum: 3,
      order: 3,
    },
    progress: {
      label: label.PROGRESS,
      icon: 'mdi-progress-check',
      items: progressStatus.seiyakuAnkenKakunin,
      isSelectBox: true,
      colNum: 3,
    },
  }),
  shukkaAnkenKakutei: (statusItems) => ({
    invoiceDate: {
      label: label.INVOICE_DATE,
      isCalendar: true,
      range: true,
      colNum: 1,
    },
    ankenNo: {
      label: label.ANKEN_NO,
      icon: 'mdi-file-document',
      isText: true,
      colNum: 1,
    },
    buyerName: {
      label: label.BUYER_NAME,
      icon: 'mdi-city-variant',
      isText: true,
      colNum: 1,
    },
    nyukinDate: {
      label: label.NYUKIN_DATE,
      isCalendar: true,
      range: true,
      colNum: 2,
    },
    shukkayoteiDate: {
      label: label.SHUKKAYOTEI_DATE,
      isCalendar: true,
      range: true,
      colNum: 2,
    },
    status: {
      label: label.STATUS,
      icon: 'mdi-progress-question',
      isSelectBox: true,
      items: statusItems,
      colNum: 2,
    },
    minyukinshukka: {
      label: label.MINYUKINSHUKKA,
      isCheckbox: true,
      colNum: 3,
    },
    yoteibiminyuryoku: {
      label: label.YOTEIBIMINYURYOKU,
      isCheckbox: true,
      colNum: 3,
    },
    progress: {
      label: label.PROGRESS,
      icon: 'mdi-progress-check',
      items: progressStatus.shukkaAnkenKakutei,
      isSelectBox: true,
      colNum: 3,
    },
  }),
  shukkaShoruiSakusei: (statusItems) => ({
    shukkayoteiDate: {
      label: label.SHUKKAYOTEI_DATE,
      isCalendar: true,
      range: false,
      colNum: 1,
    },
    shukkatoshiNo: {
      label: label.SHUKKATOSHI_NO,
      icon: 'mdi-file-document',
      isText: true,
      colNum: 1,
    },
    courier: {
      label: label.COURIER,
      icon: 'mdi-truck-fast',
      isSelectBox: true,
      items: courier,
      colNum: 1,
    },
    ankenNo: {
      label: label.ANKEN_NO,
      icon: 'mdi-file-document',
      isText: true,
      colNum: 2,
    },
    buyerName: {
      label: label.BUYER_NAME,
      icon: 'mdi-city-variant',
      isText: true,
      colNum: 2,
    },
    shutanto: {
      label: label.SHUTANTO,
      icon: 'mdi-account-search',
      isText: true,
      colNum: 2,
    },
    status: {
      label: label.STATUS,
      icon: 'mdi-progress-question',
      isSelectBox: true,
      items: statusItems,
      colNum: 3,
    },
    progress: {
      label: label.PROGRESS,
      icon: 'mdi-progress-check',
      items: progressStatus.shukkaShoruiSakusei,
      isSelectBox: true,
      colNum: 3,
    },
  }),
  shukkaJunbiHikiwatashi: (statusItems) => ({
    shukkayoteiDate: {
      label: label.SHUKKAYOTEI_DATE,
      isCalendar: true,
      range: true,
      colNum: 1,
    },
    shukkatoshiNo: {
      label: label.SHUKKATOSHI_NO,
      icon: 'mdi-file-document',
      isText: true,
      colNum: 1,
    },
    ankenNaiyo: {
      label: label.ANKEN_NAIYO,
      icon: 'mdi-file-document',
      isText: true,
      colNum: 2,
    },
    buyerName: {
      label: label.BUYER_NAME,
      icon: 'mdi-city-variant',
      isText: true,
      colNum: 2,
    },
    status: {
      label: label.STATUS,
      icon: 'mdi-progress-question',
      isSelectBox: true,
      items: statusItems,
      colNum: 3,
    },
    progress: {
      label: label.PROGRESS,
      icon: 'mdi-progress-check',
      items: progressStatus.shukkaJunbiHikiWatashi,
      isSelectBox: true,
      colNum: 3,
    },
  }),
};

export const LATEST_AFTER_TOMORROW = 'LATEST_AFTER_TOMORROW';
