const ankenNoWidth = '160px';
const statusWidth = '80px';
const bikouWidth = '100px';

import { inputs } from '@/consts';

export default {
  labels(state) {
    return state.labels;
  },
  statusItems(state) {
    return state.statusItems;
  },
  unpaidShippingItems(state) {
    return state.unpaidShippingItems;
  },
  messages(state) {
    return state.messages;
  },
  pathNames(state) {
    return state.pathNames;
  },
  pdfFileNames(state) {
    return state.pdfFileNames;
  },
  localStoreLabels(state) {
    return state.localStoreLabels;
  },
  authority(state) {
    return state.sipsAuthority;
  },
  dashboard(state) {
    return {
      inputs: {
        invoiceDate: {
          label: state.labels.INVOICE_DATE,
          isCalendar: true,
          range: true,
          colNum: 1,
        },
        nyukinDate: {
          label: state.labels.NYUKIN_DATE,
          isCalendar: true,
          range: true,
          colNum: 1,
        },
        ankenNo: {
          label: state.labels.ANKEN_NO,
          icon: 'mdi-file-document',
          isText: true,
          colNum: 1,
        },
        shukkayoteiDate: {
          label: state.labels.SHUKKAYOTEI_DATE,
          isCalendar: true,
          range: true,
          colNum: 2,
        },
        shukkaDate: {
          label: state.labels.SHUKKA_DATE,
          isCalendar: true,
          range: true,
          colNum: 2,
        },
      },
      headers: [
        {
          text: state.labels.KOUTEI,
          sortable: false,
          value: 'koutei',
        },
        {
          text: 'HK',
          sortable: false,
          value: 'HK',
        },
        {
          text: 'ME',
          sortable: false,
          value: 'ME',
        },
        {
          text: 'EU',
          sortable: false,
          value: 'EU',
        },
        {
          text: 'US',
          sortable: false,
          value: 'US',
        },
        {
          text: 'Oceania',
          sortable: false,
          value: 'Oceania',
        },
        {
          text: 'Asia',
          sortable: false,
          value: 'Asia',
        },
        {
          text: 'Africa',
          sortable: false,
          value: 'Africa',
        },
        {
          text: state.labels.GOUKEI,
          sortable: false,
          value: 'goukei',
        },
        {
          text: state.labels.BTN_CSV_OUTPUT,
          sortable: false,
          value: 'csvOutput',
        },
      ],
    };
  },
  konpoMeisai(state) {
    return {
      inputs: {
        invoiceBango: {
          label: state.labels.INVOICE_BANGO,
        },
        buyerName: {
          label: state.labels.BUYER_NAME,
          icon: 'mdi-account-tie',
        },
        hakomatomezumi: {
          label: state.labels.HAKOMATOMEZUMI,
          isCheckbox: true,
        },
        recoverInvoices: {
          label: state.labels.INVOICE_RECOVER,
        },
      },
      reactiveVar: {
        invoiceBango: '',
        buyerName: '',
        hakomatomezumi: false,
      },
      headers1: [
        {
          text: state.labels.SEIHINMEI,
          sortable: true,
          value: 'model',
        },
        {
          text: state.labels.JUUTORITSU,
          sortable: true,
          value: 'appropriation_rate',
        },

        {
          text: state.labels.SUURYOU,
          sortable: false,
          value: 'quantity',
        },
        {
          text: state.labels.BOX_TYPE,
          sortable: false,
          value: 'box_type_lbl',
        },
        {
          text: state.labels.KINGAKU,
          sortable: false,
          value: 'bid_total_price',
        },
        {
          text: state.labels.BOX_NO,
          sortable: false,
          value: 'box_no',
        },
        {
          text: state.labels.CATEGORY,
          sortable: false,
          value: 'category',
        },
        {
          text: state.labels.REMARK,
          sortable: false,
          value: 'memo',
        },
      ],
      headers2: [
        {
          text: null,
          sortable: false,
          class: 'px-0',
          value: 'key',
        },
        {
          text: state.labels.ANKEN_EDABAN,
          sortable: false,
          value: 'anken_no',
        },
        {
          text: state.labels.CARTON_NO,
          sortable: false,
          value: 'carton_no',
        },
        {
          text: state.labels.SEIHINMEI,
          sortable: false,
          value: 'model',
        },
        {
          text: state.labels.CARTON_JUUTORITSU,
          sortable: false,
          value: 'carton_total_appropriation_rate',
        },
        {
          text: state.labels.JUUTORITSU,
          sortable: false,
          value: 'appropriation_rate',
        },
        {
          text: state.labels.SOUSUURYOU,
          sortable: false,
          value: 'total_qty',
        },
        {
          text: state.labels.SUURYOU,
          sortable: false,
          value: 'quantity',
        },
        {
          text: state.labels.BOX_TYPE,
          sortable: false,
          value: 'carton_box_type_lbl',
        },
        // {
        //   text: state.labels.HAKO,
        //   sortable: false,
        //   value: "box_type_lbl",
        // },
        {
          text: state.labels.NET_WEIGHT,
          sortable: false,
          value: 'box_weight',
        },
        {
          text: state.labels.GROSS_WEIGHT,
          sortable: false,
          value: 'gross_weight',
        },
        {
          text: state.labels.ANKEN_SOUJUURYO,
          sortable: false,
          value: 'anken_total_weight',
        },
        {
          text: state.labels.KINGAKU,
          sortable: false,
          value: 'bid_total_price',
        },
        {
          text: state.labels.SOUGAKU,
          sortable: false,
          value: 'carton_bid_total_price',
        },
        {
          text: state.labels.ANKEN_SOUGAKU,
          sortable: false,
          value: 'anken_bid_total_price',
        },
        {
          text: state.labels.BOX_NO,
          sortable: false,
          value: 'box_no',
        },
        {
          text: state.labels.CATEGORY,
          sortable: false,
          value: 'category',
        },
        {
          text: state.labels.REMARK,
          sortable: false,
          value: 'memo',
        },
      ],
    };
  },
  seiyakuAnkenKakunin(state) {
    return {
      headers: [
        {
          text: state.labels.INVOICE_DATE,
          sortable: true,
          value: 'issue_date',
        },
        {
          text: state.labels.ANKEN_NO,
          sortable: true,
          value: 'matter_no',
          width: ankenNoWidth,
        },
        {
          text: state.labels.PICKING,
          sortable: false,
          value: 'picking_complete_flag',
        },
        {
          text: state.labels.BUYER_NAME,
          sortable: true,
          value: 'member_name_ja',
        },
        {
          text: state.labels.DAISUU,
          sortable: false,
          value: 'total_quantity',
        },
        {
          text: state.labels.WEIGHT,
          sortable: false,
          value: 'total_weight',
        },
        {
          text: state.labels.INVOICE_KAKAKU,
          sortable: false,
          value: 'total_price',
        },
        {
          text: state.labels.COUNTRY,
          sortable: false,
          value: 'country_name',
        },
        {
          text: state.labels.COURIER,
          sortable: false,
          value: 'courier_name',
        },
        {
          text: state.labels.COMMENT,
          sortable: false,
          value: 'comment_count',
        },
        {
          text: state.labels.STATUS,
          sortable: false,
          value: 'status_name',
          width: statusWidth,
        },
        {
          text: state.labels.KONPONAIYOSHUSEI,
          sortable: false,
          value: 'konponaiyoshusei',
        },
        {
          text: state.labels.DOC_DL,
          sortable: false,
          value: 'docDl',
        },
        {
          text: state.labels.KONPOMEISAI_DATETIME,
          sortable: false,
          value: 'packing_manualy_update_datetime',
        },
        {
          text: state.labels.SHUSEI_TANTOSHA,
          sortable: false,
          value: 'packing_manualy_updater',
        },
        {
          text: state.labels.SHIPPING_REMARK,
          sortable: false,
          value: 'shipping_description',
        },
        {
          text: state.labels.TANTOSHA,
          sortable: false,
          value: 'shipping_bikou_user_id',
        },
        {
          text: state.labels.TAIOZUMI,
          sortable: false,
          value: 'shipping_bikou_flag',
        },
        {
          text: state.labels.MINYUKINSHUKKA,
          sortable: false,
          value: 'unpaid_shipping',
        },
        {
          text: state.labels.NYUKIN_DATE,
          sortable: false,
          value: 'payment_date',
        },
        {
          text: state.labels.REMARK,
          sortable: false,
          value: 'bikou',
          width: bikouWidth,
        },
        {
          text: state.labels.EDIT,
          sortable: false,
          value: 'edit',
        },
      ],
    };
  },
  yuusenjuniSakusei(state) {
    return {
      inputs: {
        invoiceDate: {
          label: state.labels.INVOICE_DATE,
        },
      },
      headers: [
        {
          text: state.labels.INVOICE_DATE,
          sortable: true,
          value: 'issue_date',
        },
        {
          text: state.labels.KAIIN_ID,
          sortable: false,
          value: 'member_no',
        },
        {
          text: state.labels.BUYER_NAME,
          sortable: true,
          value: 'member_name_ja',
        },
        {
          text: state.labels.ANKEN_NO,
          sortable: true,
          value: 'matter_no_csv',
        },
        {
          text: state.labels.DAISUU,
          sortable: true,
          value: 'total_quantity',
        },
        {
          text: state.labels.WEIGHT,
          sortable: true,
          width: bikouWidth,
          value: 'total_weight',
        },
        {
          text: state.labels.YUUSENJUNI,
          sortable: true,
          value: 'priority',
        },
      ],
    };
  },
  shukkaAnkenKakutei(state) {
    return {
      headers: [
        {
          text: state.labels.PICKING,
          sortable: true,
          value: 'picking_complete_flag',
        },
        {
          text: state.labels.INVOICE_DATE,
          sortable: false,
          value: 'issue_date',
        },
        {
          text: state.labels.NYUKIN_DATE,
          sortable: true,
          value: 'payment_date',
        },
        {
          text: state.labels.YUUSENJUNI,
          sortable: true,
          value: 'priority',
        },
        {
          text: state.labels.SHUKKAYOTEI_DATE,
          sortable: true,
          value: 'scheduled_shipping_date',
        },
        {
          text: state.labels.SHUKKATOSHI_NO,
          sortable: false,
          value: 'shipping_seq_number',
        },
        {
          text: state.labels.BUYER_NAME,
          sortable: true,
          value: 'member_name_ja',
        },
        {
          text: state.labels.ANKEN_NO,
          sortable: true,
          value: 'matter_no',
          width: ankenNoWidth,
        },
        {
          text: state.labels.DAISUU,
          sortable: false,
          value: 'total_quantity',
        },
        {
          text: state.labels.WEIGHT,
          sortable: false,
          value: 'total_weight',
        },
        {
          text: state.labels.INVOICE_KAKAKU,
          sortable: false,
          value: 'total_price',
        },
        {
          text: state.labels.REMARK,
          sortable: false,
          value: 'bikou',
        },
        {
          text: state.labels.STATUS,
          sortable: true,
          value: 'status_name',
        },
        {
          text: state.labels.SHUKKAHORYU,
          sortable: false,
          value: 'shipment_pending_flag',
        },
        {
          text: state.labels.SHUKKATOSHIBANGOFURIWAKE,
          sortable: false,
          value: 'shipping_seq_number_group',
        },
        {
          text: state.labels.EDIT,
          sortable: false,
          value: 'edit',
        },
      ],
    };
  },
  minyukinAnkenLists(state) {
    return {
      inputs: {
        invoiceDate: {
          label: state.labels.INVOICE_DATE,
          isCalendar: true,
          range: true,
          colNum: 1,
        },
        ankenNo: {
          label: state.labels.ANKEN_NO,
          icon: 'mdi-file-document',
          isText: true,
          colNum: 1,
        },
        buyerName: {
          label: state.labels.BUYER_NAME,
          icon: 'mdi-account-cash',
          isText: true,
          colNum: 1,
        },
        status: {
          label: state.labels.STATUS,
          icon: 'mdi-progress-question',
          items: state.statusItems,
          isSelectBox: true,
          colNum: 2,
        },
      },
      headers: [
        {
          text: state.labels.ANKEN_NO,
          sortable: true,
          value: 'matter_no',
          width: ankenNoWidth,
        },
        {
          text: state.labels.BUYER_NAME,
          sortable: true,
          value: 'member_name_ja',
        },
        {
          text: state.labels.DAISUU,
          sortable: false,
          value: 'total_quantity',
        },
        {
          text: state.labels.WEIGHT,
          sortable: false,
          value: 'total_weight',
        },
        {
          text: state.labels.REMARK,
          sortable: false,
          value: 'bikou',
        },
        {
          text: state.labels.INVOICE_DATE,
          sortable: false,
          value: 'issue_date',
        },
        {
          text: state.labels.STATUS,
          sortable: false,
          value: 'status_name',
        },
        {
          text: state.labels.SHUKKAHORYU,
          sortable: false,
          value: 'shipment_pending_flag',
        },
        {
          text: state.labels.EDIT,
          sortable: false,
          value: 'edit',
        },
      ],
    };
  },
  shukkaShoruiSakusei(state, getter) {
    return {
      inputs: inputs.shukkaShoruiSakusei(getter.statusItems.filter((i) => ['5', '6'].includes(i.value))),
      headers: [
        {
          text: state.labels.SHUKKAYOTEI_DATE,
          sortable: true,
          value: 'scheduled_shipping_date',
        },
        {
          text: state.labels.SHUKKATOSHI_NO,
          sortable: true,
          value: 'shipping_seq_number',
          class: 'header-stick-to-the-left',
          cellClass: 'stick-to-the-left',
          fixedWidth: 0,
          width: '120px',
        },
        {
          text: state.labels.KAIIN_ID,
          sortable: false,
          value: 'member_no',
          class: 'header-stick-to-the-left',
          cellClass: 'stick-to-the-left',
          fixedWidth: 120, // 120 = 出荷通し番号ヘッダーの位置(0) + 出荷通し番号ヘッダーのwidth(120)
          width: '150px',
        },
        {
          text: state.labels.BUYER_NAME_ABBR,
          sortable: true,
          value: 'buyerName',
          class: 'header-stick-to-the-left',
          cellClass: 'stick-to-the-left',
          fixedWidth: 270, // 270 = 会員番号ヘッダーの位置(120) + 会員番号ヘッダーのwidth(150)
        },
        {
          text: state.labels.COUNTRY,
          sortable: false,
          value: 'country_name',
        },
        {
          text: state.labels.ANKEN_NAIYO,
          sortable: true,
          width: ankenNoWidth,
          value: 'matter_no_list',
        },
        {
          text: state.labels.INVOICE_SOUGAKU,
          sortable: false,
          value: 'total_price',
        },
        {
          text: state.labels.TOTAL_CARTONS,
          sortable: false,
          value: 'total_cartons',
        },
        {
          text: state.labels.TOTAL_QUANTITY,
          sortable: false,
          value: 'total_quantity',
        },
        {
          text: state.labels.WEIGHT,
          sortable: false,
          value: 'total_weight',
        },
        {
          text: state.labels.COURIER,
          sortable: true,
          value: 'courier_name',
        },
        {
          text: state.labels.SHUTANTO,
          sortable: true,
          value: 'main_shipping_data_checker',
        },
        {
          text: state.labels.REMARK,
          sortable: false,
          width: bikouWidth,
          class: 'px-0',
          value: 'bikou_list',
        },
        {
          text: state.labels.SOUFUSAKI,
          sortable: false,
          value: 'soufusaki',
        },
        {
          text: state.labels.ARMS_NYUKINSHORI,
          sortable: false,
          value: 'armsNyukinshori',
        },
        {
          text: state.labels.YOYAKU_BANGO,
          sortable: false,
          value: 'reservation_number',
        },
        {
          text: state.labels.SHIPPING_REMARK,
          sortable: false,
          value: 'shipping_description',
        },
        {
          text: state.labels.SHIPPING_REMARK_CONFIRM,
          sortable: false,
          value: 'shipping_bikou_flag',
        },
        {
          text: state.labels.SHUKKA_SHORUI,
          sortable: false,
          value: 'shukkaShorui',
        },
        {
          text: state.labels.SHUKKA_SHORUI_PRINT,
          sortable: false,
          value: 'shukkaShoruiPrint',
        },
        {
          text: state.labels.SHORUISASHIKAE,
          sortable: false,
          value: 'shoruisashikae',
        },
        {
          text: state.labels.HENKO_DATETIME,
          sortable: false,
          value: 'contents_update_datetime',
        },
        {
          text: state.labels.SHUKKA_DATA_TSUSHIN,
          sortable: false,
          value: 'shukkaDataTsushin',
        },
        {
          text: state.labels.SHUKKA_PDF_ZIP,
          sortable: false,
          value: 'shipping_label_link',
        },
        {
          text: state.labels.CHECKTANTO,
          sortable: false,
          value: 'manager_name',
        },
        {
          text: state.labels.DOUBLE_CHECK,
          sortable: false,
          value: 'doubleCheck',
        },
        {
          text: state.labels.TENPU_SHORUI,
          sortable: false,
          value: 'operator_documents',
        },
        {
          text: state.labels.STATUS,
          sortable: false,
          value: 'status_name',
          width: ankenNoWidth,
        },
        {
          text: state.labels.CSL_MATCHING_RESULT,
          sortable: true,
          value: 'csl_matching_result',
        },
        {
          text: state.labels.CSL_MATCHING_CONFIRM,
          sortable: true,
          value: 'csl_matching_confirm',
        },
        {
          text: state.labels.CSL_MATCHING_MEMO,
          sortable: false,
          value: 'csl_matching_memo',
        },
        {
          text: state.labels.EDIT,
          sortable: false,
          value: 'editing',
          class: 'header-stick-to-the-right',
          cellClass: 'stick-to-the-right',
        },
      ],
    };
  },
  shukkaJunbiHikiwatashi(state) {
    return {
      headers: [
        {
          text: state.labels.SHUKKATOSHI_NO,
          class: 'header-stick-to-the-left',
          cellClass: 'stick-to-the-left',
          fixedWidth: 0,
          sortable: true,
          value: 'shipping_seq_number',
        },
        {
          text: state.labels.KAIIN_ID,
          class: 'header-stick-to-the-left',
          cellClass: 'stick-to-the-left',
          sortable: false,
          value: 'member_no',
          fixedWidth: 120, // 120 = 出荷通し番号ヘッダーの位置(0) + 出荷通し番号ヘッダーのwidth(120)
          width: '150px',
        },
        {
          text: state.labels.BUYER_NAME,
          class: 'header-stick-to-the-left',
          cellClass: 'stick-to-the-left',
          fixedWidth: 270, // 270 = 会員番号ヘッダーの位置(120) + 会員番号ヘッダーのwidth(150)
          sortable: true,
          value: 'member_name_ja',
        },
        {
          text: state.labels.ANKEN_NAIYO,
          sortable: true,
          value: 'matter_no_list',
        },
        {
          text: state.labels.WEIGHT,
          sortable: false,
          value: 'total_weight',
        },
        {
          text: state.labels.REMARK,
          sortable: false,
          value: 'bikou_list',
        },
        {
          text: state.labels.COURIER,
          sortable: false,
          value: 'courier_name',
        },
        {
          text: state.labels.STATUS,
          sortable: false,
          value: 'status_name',
        },
        {
          text: state.labels.COMMENT,
          sortable: false,
          value: 'comment_count',
        },
        {
          text: state.labels.SHUKKA_SHORUI,
          sortable: false,
          value: 'shukkaShorui',
        },
        {
          text: state.labels.SHUKKA_PDF_ZIP,
          sortable: false,
          value: 'shipping_label_link',
        },
        {
          text: state.labels.SHUKKA_SHORUI_PRINT,
          sortable: false,
          value: 'shukkaShoruiPrint',
        },
        {
          text: state.labels.TENPU_SHORUI,
          sortable: false,
          value: 'operator_documents',
        },
        {
          text: state.labels.PICKING_KANRYO_KAKUNIN,
          sortable: false,
          value: 'picking_completed_flag',
        },
        {
          text: state.labels.ARMS_SHUKKASHORI,
          sortable: false,
          value: 'arms_shipment_process_confirmed_flag',
        },
        {
          text: state.labels.CENTER_TENPU_SHORUI,
          sortable: false,
          value: 'center_documents',
        },
        {
          text: state.labels.CENTER_TENPU_SHORUI_KAKUNIN,
          sortable: false,
          value: 'center_attached_docs_confirmed_flag',
        },
        {
          text: state.labels.HIKIWATASHI_KANRYO,
          sortable: false,
          value: 'shipped_confirmed_flag',
        },
        {
          text: state.labels.TANTOSHA,
          sortable: false,
          value: 'shipping_check_user_name',
        },
        {
          text: state.labels.TRACKING_NO,
          sortable: false,
          value: 'tracking_number',
        },
        {
          text: state.labels.EDIT,
          class: 'header-stick-to-the-right',
          cellClass: 'stick-to-the-right',
          sortable: false,
          value: 'edit',
        },
      ],
    };
  },
  shukkaZumiAnkenList(state) {
    return {
      inputs: {
        invoiceDate: {
          label: state.labels.INVOICE_DATE,
          isCalendar: true,
          colNum: 1,
          range: true,
        },
        nyukinDate: {
          label: state.labels.NYUKIN_DATE,
          isCalendar: true,
          colNum: 1,
          range: true,
        },
        shukkaDate: {
          label: state.labels.SHUKKA_DATE,
          isCalendar: true,
          range: true,
          colNum: 1,
        },
        shukkatoshiNo: {
          label: state.labels.SHUKKATOSHI_NO,
          icon: 'mdi-file-document',
          isText: true,
          colNum: 2,
        },
        ankenNaiyo: {
          label: state.labels.ANKEN_NAIYO,
          icon: 'mdi-file-document',
          isText: true,
          colNum: 2,
        },
        buyerName: {
          label: state.labels.BUYER_NAME,
          icon: 'mdi-account-cash',
          isText: true,
          colNum: 2,
        },
        trackingNo: {
          label: state.labels.TRACKING_NO,
          isText: true,
          colNum: 3,
        },
      },
      headers: [
        {
          text: state.labels.SHUKKATOSHI_NO,
          sortable: true,
          value: 'shipping_seq_number',
        },
        {
          text: state.labels.BUYER_NAME,
          sortable: true,
          value: 'member_name_ja',
        },
        {
          text: state.labels.COUNTRY,
          sortable: true,
          value: 'country_name',
        },
        {
          text: state.labels.ANKEN_NAIYO,
          sortable: true,
          value: 'matter_no_list',
        },
        {
          text: state.labels.WEIGHT,
          sortable: false,
          value: 'total_weight',
        },
        {
          text: state.labels.COMMENT,
          sortable: false,
          value: 'comment_count',
        },
        {
          text: state.labels.REMARK,
          sortable: false,
          value: 'bikou_list',
        },
        {
          text: state.labels.COURIER,
          sortable: false,
          value: 'courier_name',
        },
        {
          text: state.labels.STATUS,
          sortable: false,
          value: 'status_name',
        },
        {
          text: state.labels.HIKIWATASHI_DATETIME,
          sortable: false,
          value: 'shipping_datetime',
        },
        {
          text: state.labels.TANTOSHA,
          sortable: false,
          value: 'shipping_check_user_name',
        },
        {
          text: state.labels.HIKIWATASHI_CANCEL,
          sortable: false,
          value: 'hikiwatashiCancel',
        },
        {
          text: state.labels.SHORUI,
          sortable: false,
          value: 'shorui',
        },
        {
          text: state.labels.TRACKING_NO,
          sortable: false,
          value: 'tracking_number',
        },
        {
          text: state.labels.EDIT,
          sortable: false,
          value: 'edit',
        },
      ],
    };
  },
  constantMaster(state) {
    return {
      inputs: {
        keyString: {
          label: state.labels.KEY_STRING,
          icon: 'mdi-key',
          isText: true,
          colNum: 1,
        },
        name: {
          label: state.labels.NAME,
          icon: 'mdi-account-cash',
          isText: true,
          colNum: 1,
        },
        value1: {
          label: state.labels.VALUE_1,
          icon: 'mdi-file-document',
          isText: true,
          colNum: 1,
        },
        value2: {
          label: state.labels.VALUE_2,
          icon: 'mdi-file-document',
          isText: true,
          colNum: 2,
        },
        value3: {
          label: state.labels.VALUE_3,
          icon: 'mdi-file-document',
          isText: true,
          colNum: 2,
        },
        value4: {
          label: state.labels.VALUE_4,
          icon: 'mdi-file-document',
          isText: true,
          colNum: 2,
        },
        value5: {
          label: state.labels.VALUE_5,
          icon: 'mdi-file-document',
          isText: true,
          colNum: 2,
        },
        discription: {
          label: state.labels.DISCRIPTION,
          icon: 'mdi-exclamation',
          isTextArea: true,
          colNum: 3,
        },
        order: {
          label: state.labels.ORDER,
          icon: 'mdi-arrow-down',
          isText: true,
          colNum: 3,
        },
      },
      reactiveVar: {
        keyString: '',
        name: '',
        value1: '',
        value2: '',
        value3: '',
        value4: '',
        value5: '',
        discription: '',
        order: null,
      },
      headers: [
        {
          text: state.labels.KEY_STRING,
          sortable: true,
          value: 'keyString',
        },
        {
          text: state.labels.NAME,
          sortable: true,
          value: 'name',
        },
        {
          text: state.labels.VALUE_1,
          sortable: false,
          value: 'value1',
        },
        {
          text: state.labels.VALUE_2,
          sortable: false,
          value: 'value2',
        },
        {
          text: state.labels.VALUE_3,
          sortable: false,
          value: 'value3',
        },
        {
          text: state.labels.VALUE_4,
          sortable: false,
          value: 'value4',
        },
        {
          text: state.labels.VALUE_5,
          sortable: false,
          value: 'value5',
        },
        {
          text: state.labels.DISCRIPTION,
          sortable: false,
          value: 'discription',
          align: 'start',
          class: 'px-3',
        },
        {
          text: state.labels.ORDER,
          sortable: false,
          value: 'order',
        },
        {
          text: '',
          sortable: false,
          value: 'operation',
          width: '130px',
        },
      ],
    };
  },
  user: (state) => state.user,
};
