export default {
  setShippingDate(state, payload) {
    state.shukkayoteiDate = payload;
  },
  setLatestAfterTomorrow(state, payload) {
    state.latestAfterTomorrow = payload;
  },
  setSubmitData(state, payload) {
    state.submitData = payload;
  },
  setPackingsReadyForShipment(state, payload) {
    state.packingsReadyForShipment = payload;
  },
  setShipTo(state, payload) {
    state.shipTo = payload;
  },
  setProgress(state, payload) {
    state.submitData.progress = payload;
  },
  setInvoiceInfo(state, payload) {
    state.invoiceInfo = payload;
  },
  setInvoiceDetails(state, payload) {
    state.invoiceDetails = payload;
  },
  setExchangeRate(state, payload) {
    state.rate = payload;
  },
  setCslMatchingResult(state, payload) {
    state.cslMatchingResult = payload;
  },
};
