import actions from './actions.js';
import getters from './getters.js';
import mutations from './mutations.js';

export default {
  namespaced: true,
  state() {
    return {
      labels: {
        ANKEN_EDABAN: '案件枝番',
        ANKEN_NAIYO: '案件内容',
        ANKEN_NO: '案件No',
        ANKEN_SOUGAKU: '案件総額',
        ANKEN_SOUJUURYO: '案件総重量',
        ARMS_NYUKINSHORI: 'ARMS\r\n入金処理',
        ARMS_SHUKKASHORI: 'ARMS\r\n出荷処理確認',
        BOX_NO: 'BoxNo',
        BOX_TYPE: '箱タイプ',
        BUYER_NAME: 'バイヤー名',
        BUYER_NAME_ABBR: 'バイヤー名(略称)',
        CATEGORY: 'カテゴリ',
        CARTON_NO: 'CartonNo',
        CENTER_TENPU_SHORUI_KAKUNIN: 'センター\r\n添付書類確認',
        CENTER_TENPU_SHORUI: 'センター\r\n添付書類',
        COMMENT: 'コメント',
        COUNTRY: '国名',
        COURIER: 'クーリエ',
        DAISUU: '台数',
        DOC_DL: '書類DL',
        DOUBLE_CHECK: 'ダブル\r\nチェック',
        EDIT: '編集',
        GOUKEI: '合計',
        GROSS_WEIGHT: '総重量',
        HAKO: '箱',
        HAKOMATOMEZUMI: '箱まとめ済',
        HENKO_DATETIME: '出荷書類\r\n変更日時',
        HIKIWATASHI_CANCEL: '引き渡し\r\nキャンセル',
        HIKIWATASHI_DATETIME: '引き渡し日時',
        HIKIWATASHI_KANRYO: '引き渡し\r\n完了',
        HIKIWATASHI_MAE: '引渡前',
        INVOICE_BANGO: 'Invoice番号',
        INVOICE_DATE: 'Invoice発行日',
        INVOICE_KAKAKU: 'Invoice価格',
        INVOICE_RECOVER: 'インボイス回復',
        INVOICE_SOUGAKU: 'Invoice総額',
        JUUTORITSU: '充当率',
        CARTON_JUUTORITSU: '総充当率',
        KAIIN_ID: '会員ID',
        KINGAKU: '金額',
        KOUTEI: '工程',
        KONPOMEISAI_DATETIME: '修正日時',
        SHUSEI_TANTOSHA: '修正担当者',
        KONPONAIYOSHUSEI: '梱包明細作成\r\n内容修正',
        MINYUKIN: '未入金',
        MINYUKINSHUKKA: '未入金出荷',
        MISHUKKA: '未出荷',
        NET_WEIGHT: '純重量',
        NYUKIN_DATE: '入金日',
        PICKING_KANRYO_KAKUNIN: 'ピッキング\r\n完了確認',
        PICKING: 'ピッキング',
        REMARK: '備考',
        SEIHINMEI: '商品名',
        SHIPPING_REMARK_ARI: 'Shipping備考あり',
        SHIPPING_REMARK_CONFIRM: 'Shipping\r\n備考確認',
        SHIPPING_REMARK: 'Shipping備考',
        SHORUI: '書類',
        SHORUI_SAKUSEI_CHU: '書類作成中',
        SHORUISASHIKAE: '出荷書類\r\n差し替え',
        SHUKKA_ANKEN_NO: '出荷案件',
        SHUKKA_CANCEL: '出荷キャンセル',
        SHUKKA_DATA_TSUSHIN: '出荷データ送信',
        SHUKKA_PDF_ZIP: '出荷PDF',
        SHUKKA_DATE: '出荷日',
        SHUKKA_SHORUI: '出荷書類',
        SHUKKA_SHORUI_PRINT: '出荷書類印刷',
        SHUKKA_YOTEI_KAKUTEI_MAE: '出荷予定確定前',
        SHUKKA_YOTEI_KAKUTEI_GO: '出荷予定確定後',
        SHUKKA_YOTEIBI_KAUTEI: 'ー出荷予定日確定',
        SHUKKA_YOTEIBI_MIKAUTEI: 'ー出荷予定日未確定',
        SHUKKAHORYU: '出荷保留',
        SHUKKAHORYUANKEN: 'ー出荷保留案件',
        SHUKKATOSHI_NO: '出荷通しNo',
        SHUKKATOSHIBANGOFURIWAKE: '出荷通し番号\n振分',
        SHUKKAYOTEI_DATE: '出荷予定日',
        SOUFUSAKI: '送付先',
        STATUS: 'ステータス',
        SUURYOU: '数量',
        SOUSUURYOU: '総数量',
        SOUGAKU: 'Carton総額',
        TAIOZUMI: '対応済',
        TOTAL_QUANTITY: '総台数',
        TOTAL_CARTONS: '総Carton数',
        CHECKTANTO: '送信結果\r\nチェック',
        SHUTANTO: '主担当',
        TANTOSHA: '担当者',
        TENPU_SHORUI: '運営\r\n添付書類',
        TRACKING_NO: '追跡番号',
        WEIGHT: '重量',
        PACKAGING_MATERIALS_WEIGHT: '重量',
        HEIGHT: '縦幅',
        WIDTH: '横幅',
        DEPTH: '高さ',
        YOTEIBIMINYURYOKU: '予定日未入力',
        YOUSEKI: '容積',
        YOYAKU_BANGO: '予約番号',
        YUUSENJUNI: '優先順位',
        BTN_ADD: '入力',
        BTN_ANKEN_CANCEL: '案件キャンセル',
        BTN_AUTO_MATOME: '自動まとめ',
        BTN_BOX_CANCEL: 'Boxキャンセル',
        BTN_UNDO_BOX_CANCEL: 'Boxキャンセル取消',
        BTN_BUNKATSU_ANKEN_MODOSHI: '分割案件戻し',
        BTN_RESET: 'リセット',
        BTN_CANCEL: 'キャンセル',
        BTN_UNDO_SHIP: 'SHIP取り消し',
        BTN_CONFIRM: '確認',
        BTN_CSV_INPUT: 'CSV取込',
        BTN_CSV_OUTPUT: 'CSV出力',
        BTN_AUTO_NUMBER: '自動採番',
        BTN_DEL_NUMBER: '採番取消',
        BTN_DOWNLOAD: 'DL',
        BTN_HAKO_MATOME_LIST_DL: '箱まとめリストDL',
        BTN_HENKO_HOZON: '変更保存',
        BTN_IKKI_DOWNLOAD: '一括DL',
        BTN_INVOICE_DETAILS_SHINKI: 'Invoice・Details取込',
        BTN_EXECUTE: '実行',
        BTN_KONPO_MEISAI_DL: '梱包明細DL',
        BTN_NYUKIN_CSV: '入金データCSV取込',
        BTN_PREVIEW: 'プレビュー',
        BTN_RETRIEVE: '取得',
        BTN_SEARCH: '検索',
        BTN_SELECT: '選択',
        BTN_CHANGE: '変更',
        BTN_CHECK: 'チェック',
        BTN_SENTAKU_CARTON_BETSU_ANKENKA: '選択Carton別案件化',
        BTN_SHUKKA_YOTEIBI_SETTEI: '出荷予定日設定',
        BTN_WEIGHT_LIST_TORIKOMI: '重量リスト取込',
        BTN_SHUKKA_SHIJI_KAIJO: '出荷指示解除',
        BTN_SHUKKA_SHORUI_DL: '出荷書類ダウンロード',
        BTN_YOYAKUBANGO_IKKI_NYURYOKU: '予約番号一括入力',
        BTN_INVOICE_REMAKE: '修正用CSV DL',
        BTN_OVERWRITE: '上書きして入力',
        BTN_NOT_OVERWRITE: '上書きせず入力',
        KEY_STRING: '定数キー',
        NAME: '名前',
        VALUE_1: '値1',
        VALUE_2: '値2',
        VALUE_3: '値3',
        VALUE_4: '値4',
        VALUE_5: '値5',
        DISCRIPTION: '説明',
        ORDER: '表示順',
        REGISTER: '登録',
        DELETE: '削除',
        DELETE_CONFIRM: '削除確認',
        REGISTER_CONFIRM: '登録確認',
        COMPLETE: '完了',
        CLOSE: '閉じる',
        UPDATE: '更新',
        UPDATE_CONFIRM: '更新確認',
        MULTI_DOWNLOAD_BUTTON: '一括DL',
        EXPORT_RESULT_CSV: 'CSVデータ出力',
        YES: 'はい',
        CSL_MATCHING_RESULT: 'CSL照合結果',
        CSL_MATCHING_CONFIRM: 'CSL照合確認',
        CSL_MATCHING_MEMO: 'CSL照合備考',
      },
      messages: {
        /**
         * 案件分割されたら「梱包明細」画面に上下枠移動処理とボックスキャンセル処理を制限する
         */
        HAS_EDABAN:
          '分割されている案件には下記操作を制限してます。<br>- 上下枠移動、<br>- ボックスキャンセルとボックキャンセル戻し<br>分割案件戻し処理を行なってから操作して下さい。',
        /**
         * 「梱包明細画面」選択カートン別案件化と分割戻し処理をステータス4までに制限
         */
        BRANCHING_LIMIT_BY_STATUS: 'ステータスが「書類作成中」以降になっているため、<br>分割と分割戻しはできません。',
        UNDO_ANKEN_CANCEL_CONFIRM: '案件キャンセルを取り消ししてもよろしいですか？',
        M0001: '選択したボックスの合計充当率が１００％を超えてます。<br>確認してから梱包して下さい。',
        M0002: '選択したボックスの箱タイプが複数あります。<br>梱包するカートン箱タイプを確認してから梱包して下さい。',
        M0003: '選択したボックスの合計充当率が中途半端になってます。<br>確認してから梱包して下さい。',
        M0004: 'iPadとiPhoneが混ぜてます。<br>確認してから梱包して下さい。',
        M0005:
          '同じ番号の案件が存在しています。案件キャンセルを取り消すには、有効な案件をキャンセルしてから再度この案件でキャンセル取り消しを行なって下さい。',
        DELETE_CONFIRM: '削除してもよろしいですか？',
        UPDATE_CONFIRM: '更新してもよろしいですか？',
        UPDATE_PICKING_COMP_CONFIRM: 'ピッキングが完了しましたか？',
        UPDATE_PICKING_WORK_CONFIRM: 'ピッキング作業中に戻しますか？',
        REGISTER_CONFIRM: '登録します。よろしいですか？',
        REGISTER_COMPLETE: '登録が成功しました。',
        PRIORITY_REGISTER_CONFIRM: '自動採番登録処理を行います。よろしいですか？',
        PRIORITY_REGISTER_COMPLETE: '優先順位一覧の登録が完了しました。',
        PRIORITY_DELETE_CONFIRM: '採番取り消します。よろしいですか？',
        PRIORITY_DELETED: '優先順位一覧の採番取り消しが完了しました。',
        PICKING_STATUS_UPDATED: 'ピッキングのステータスを更新しました。',
        DELETE_COMPLETE: '削除が完了しました。',
        UPDATE_COMPLETE: '更新が完了しました。',
        SHIPPING_DOCUMENT_UPDATE_COMPLETE: '更新が完了しました。<br>出荷種類を添付ください。',
        NO_DEVICE_INFO_IN_PACKING_MASTER: '梱包マスタに商品名が存在しません。',
        INVOICE_NOT_FOUND_ERROR: '選択されたInvoiceはキャンセルもしくは出荷済みされています。',
        SYSTEM_ERROR: 'システムエラーが発生しました。',
        ANKEN_CANCEL_CONFIRM:
          "下記Invoice番号をキャンセルしますか？<br>確認後取り消し出来ないことご注意ください。<br>Invoice番号：<span class='font-italic font-weight-medium'>",
        COURIER_SHIPMENT_CANCEL_CONFIRM:
          "下記出荷をクーリエにキャンセル指示を送りますか？<br>確認後取り消し出来ないことご注意ください。<br>出荷通し番号：<span class='font-italic font-weight-medium'>",
        COURIER_SHIPMENT_HARD_CANCEL_CONFIRM:
          "<span class='font-weight-bold red--text'>注意：クーリエにキャンセル連絡済ですか？クーリエに事前に連絡してからこの処理を行なってください。</span><br>下記出荷をキャンセルしますか？<br>確認後取り消し出来ないことご注意ください。<br>出荷通し番号：<span class='font-italic font-weight-medium'>",
        SHIPMENT_CANCEL_CONFIRM:
          "下記出荷通しNoをキャンセルしますか？<br>確認後取り消し出来ないことご注意ください。<br>出荷通し番号：<span class='font-italic font-weight-medium'>",
        NO_MEMBER_ID_ON_SIPS:
          '＊この会員はSIPSの会員マスターに登録されてないためインボイス回復できません。ARMSからSIPSに会員登録を行ってからインボイス回復をやり直してください。',
        HAS_RESERVATION_NUMBER:
          '下記の出荷通し番号には、既に予約番号が入力されています。<br>上書きしてもよろしいでしょうか？',
        HAS_NO_RESERVATION_NUMBER: '※集荷予約番号が取得されていない出荷通しNoがあります。',
        CSL_MATCHING_DESCRIPTION: '以下の情報を照合します。',
        CSL_MATCHING_HIT_DESCRIPTION: '米国CSLに該当する可能性があります。',
        CSL_MATCHING_NO_HIT_DESCRIPTION: '該当無し。',
        CSL_MATCHING_COMPLETE: '照合が完了しました。',
      },
      pathNames: {
        DASHBOARD: 'ダッシュボード',
        SEIYAKU_ANKEN_KAKUNIN: '成約案件確認',
        YUUSENJUNI_SAKUSEI: '優先順位作成',
        SHUKKA_ANKEN_KAKUTEI: '出荷案件確定',
        SHUKKA_SHORUI_SAKUSEI: '出荷書類作成',
        SHUKKA_JUNBI_HIKIWATASHI: '出荷準備-引渡',
        SHUKKA_ZUMI_ANKEN: '出荷済み案件',
        MINYUKIN_ANKEN_LISTS: '未入金案件一覧',
        SEIGEN_JOHO_KANRI: '制限情報管理',
        KONPOMEISAI: '梱包明細',
        CONSTANT_MASTER: '定数マスタ',
        BOX_TYPE_MASTER: '箱タイプマスタ',
        COURIER_LIMIT_MASTER: 'クーリエ制限マスタ',
      },
      sipsAuthority: {
        operator: { name: '（運営）', auth: 1 },
        center: { name: '（センター）', auth: 2 },
        overseaBusiness: { name: '（海外営業）', auth: 3 },
        systemAdmin: { name: '（システム管理者）', auth: 9 },
      },
      localStoreLabels: {
        SESSION_TOKEN: 'jwt',
      },
      statusItems: [],
      courierNames: [
        { id: 1, value: 'FedEx' },
        { id: 2, value: 'UPS' },
        { id: 3, value: 'DHL' },
      ],
      unpaidShippingItems: [
        { id: 0, value: '未入金出荷実施' },
        { id: 1, value: '入金済' },
      ],
      user: {},
      progressStatus: {
        seiyakuAnken: [
          { text: '総件数', value: 'TOTAL' },
          { text: '作業中', value: 'DOING' },
          { text: '未入金', value: 'UNPAID' },
        ],
        shukkaAnkenKakutei: [
          { text: '総件数', value: 'TOTAL' },
          { text: '作業中', value: 'DOING' },
          { text: '作業済', value: 'DONE' },
          { text: '出荷保留', value: 'ON_HOLD' },
        ],
        shukkaShoruiSakusei: [
          { text: '総件数', value: 'TOTAL' },
          { text: '作業中', value: 'DOING' },
          { text: '作業済', value: 'DONE' },
        ],
        shukkaJunbiHikiWatashi: [{ text: '作業中', value: 'DOING' }],
      },
      pdfFileNames: {
        SEIYAKU_ANKEN_KAKUNIN: 'seiyaku_anken_kakunin_manual.pdf',
        YUUSENJUNI_SAKUSEI: 'yuusen_juni_manual.pdf',
        SHUKKA_ANKEN_KAKUTEI: 'shukka_anken_kakutei_manual.pdf',
        SHUKKA_SHORUI_SAKUSEI: 'shukka_shorui_sakusei_manual.pdf',
        SHUKKA_JUNBI_HIKIWATASHI: 'shukka_junbi_hikiwatashi_manual.pdf',
        SHUKKA_ZUMI_ANKEN: 'shukka_zumi_anken_manual.pdf',
        KONPOMEISAI: 'konpomeisai_manual.pdf',
        COURIER_RULES: 'courier_rules_manual.pdf',
      },
    };
  },
  mutations,
  actions,
  getters,
};
