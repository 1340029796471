import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      shukkaZumiAnkenItems: [],
      reactiveVar: {
        invoiceDate: [],
        shukkatoshiNo: '',
        ankenNaiyo: '',
        buyerName: '',
        nyukinDate: [],
        shukkaDate: [],
        trackingNo: '',
      },
      commentItems: [],
    };
  },
  mutations,
  actions,
  getters,
};
