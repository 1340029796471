export default {
  submitData(state) {
    return state.submitData;
  },
  searchConditions: (state) => state.searchConditions,
  shukkayoteiDate: (state) => state.shukkayoteiDate,
  latestAfterTomorrow: (state) => state.latestAfterTomorrow,
  packingsReadyForShipment(state) {
    return state.packingsReadyForShipment.map((packing) => ({
      ...packing,
      id: packing.invoice_no,
      editing: packing.editing || false,
      buyerName: packing.member_name_ja,
      newValue: {
        csl_matching_confirm_flag: packing.csl_matching_confirm_flag,
      },
    }));
  },
  shipTo(state) {
    return state.shipTo ? state.shipTo[0] : null;
  },
  invoiceInfo(state) {
    return state.invoiceInfo;
  },
  invoiceDetails(state) {
    const details = [];
    state.invoiceDetails.forEach((detail, index) => {
      details.push({
        ...detail,
        no: index + 1,
      });
    });
    return details;
  },
  rate(state) {
    return state.rate;
  },
  cslMatchingResult: (state) => state.cslMatchingResult,
};
